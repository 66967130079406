import React, { useEffect, useState, forwardRef } from 'react'
import cardValidator from 'card-validator'
import { useTranslation } from 'react-i18next'
import { NumberFormatBase } from 'react-number-format'
import { Button, TextField } from '@mui/material'

import { usePaymentService } from '../../services/paymentService'
import { CreditCard } from '../CreditCard'

import images from '../img/utils'

const NumberFormatCustom = forwardRef(function NumberFormatCustom(props, ref) {
  const { onChange, ...other } = props

  return (
    <NumberFormatBase
      {...other}
      format="#### #### #### ####"
      getInputRef={ref}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value
          }
        })
      }}
      thousandSeparator
      isNumericString
    />
  )
})

const CreditCardForm = () => {
  const [cardNumber, setCardNumber] = useState('')
  const [image, setImage] = useState(images.visa)
  const [isCardValid, setCardValid] = useState(true)
  const [t] = useTranslation()

  const { putWithdrawalInfo, putWithdrawalSelectedId } = usePaymentService()

  useEffect(() => {
    putWithdrawalSelectedId()
  }, [])

  const handleChange = e => {
    setCardValid(true)
    setCardNumber(e.target.value)
    const isValid = cardValidator.number(e.target.value.toString())
    if (isValid.isValid) {
      setCardValid(false)
    }

    let re = new RegExp('^4')
    if (e.target.value.match(re) !== null) return setImage(images.visa)
    re = new RegExp('^5[1-5]')
    if (e.target.value.match(re) !== null) return setImage(images.mastercard)
    return setImage(images.visa)
  }

  return (
    <div className="card-form">
      <div className="card-form__inner">
        <div className="credit-card">
          <CreditCard cardNumber={cardNumber} image={image} />
        </div>

        <TextField
          InputProps={{ inputComponent: NumberFormatCustom }}
          onChange={handleChange}
          style={{ width: '100%' }}
          value={cardNumber}
          id="outlined-required"
          label="Card Number"
          autoFocus
          onKeyPress={e =>
            isCardValid ? '' : e.key === 'Enter' && putWithdrawalInfo(cardNumber.replace(/\s/g, ''))
          }
        />
        <div style={{ display: 'flex' }}>
          <Button
            onClick={() => putWithdrawalInfo(cardNumber.replace(/\s/g, ''))}
            variant="contained"
            style={{ marginTop: '10px' }}
            disabled={isCardValid}
          >
            {t('buttons.submit')}
          </Button>
          <Button variant="contained" style={{ marginTop: '10px' }}>
            {t('buttons.cancel')}
          </Button>
        </div>
      </div>
    </div>
  )
}

export { CreditCardForm }
