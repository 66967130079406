import React, { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import { RouterProvider } from 'react-router-dom'

import { App, router } from './components/App'
import { Loading } from './components/Loading'

import './translations/i18n'
import './index.css'

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <RouterProvider router={router}>
    <Suspense fallback={<Loading />}>
      <App />
    </Suspense>
  </RouterProvider>
)
