import React from 'react'

import cardImgage from '../img/card-5.jpg'
import chip from '../img/chip.webp'

const CreditCard = ({ cardNumber, image }) => {
  return (
    <div className="card-front">
      <img className="card-front__image" src={cardImgage} />
      <img className="card-front__symbol" src={image} />
      <img className="card-front__chip" src={chip} />
      <p className="card-front__number">
        {Boolean(cardNumber) && cardNumber.match(/.{1,4}/g).join(' ')}
      </p>
    </div>
  )
}

export { CreditCard }
