const RE_REGEXP_STR = /^\/(.+)\/(.*)$/u

/**
 * Convert a string to the Regular Expression.
 *
 * @param {string} string The string to convert.
 * @returns {string} Returns the `RegExp`.
**/
export function toRegExp (string) {
  const parts = RE_REGEXP_STR.exec(string)
  if (parts) {
    return new RegExp(parts[1], parts[2])
  }
  return new RegExp(`^${escape(string)}$`)
}