import React from 'react'

import './style.scss'

const DepositFailed = () => {
  const hashUrl = window.location.hash.slice(1)

  setTimeout(() => {
    window.location = hashUrl
  }, 5000)

  return (
    <div className="container__failed">
      <div className="wrapper">
        <div className="landing-page">
          <div style={{ textAlign: 'center' }} className="icon__download"></div>
          <h1 className="failed__heading">
            {navigator.language === 'it' ? 'Transazione non riuscita' : navigator.language === 'ru-RU' ? 'Оплата не удалась' : 'Deposit Failed'}
          </h1>
          <p className="failed__text">
            {navigator.language === 'it' ? 'Per favore riprova' : navigator.language === 'ru-RU' ? 'Попробуйте оплатить заказ еще раз' : 'Please try again '}
          </p>
          <button className="try-again__button">
            <a href={hashUrl} style={{ color: '#fff', textDecoration: 'none' }}>
              {navigator.language === 'it' ? 'Per favore riprova' : navigator.language === 'ru-RU' ? 'В магазин' : 'Try again '}
            </a>
          </button>
        </div>
      </div>
    </div>
  )
}

export { DepositFailed }
