import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import PropTypes from 'prop-types'
import { Tabs, Tab, Box, TextField } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useTranslation } from 'react-i18next'

import { toRegExp } from '../../../utils/regexp'
import { usePaymentService } from '../../../services/paymentService'

import { PaymentDialog } from '../../Dialog'
import { Loading } from '../../Loading'
import { ErrorMessage } from '../../ErrorMessage'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ pt: 4, pl: 4 }}>{children}</Box>}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`
  }
}

const Payment = () => {
  const [paymentMethods, setPaymentMethods] = useState([])
  const [paymentInfo, setPaymentInfo] = useState({})
  const [open, setOpen] = useState(false)
  const [fields, setFields] = useState({})
  const [validation, setValidation] = useState(false)
  const [payData, setPayData] = useState({})
  const [oneMethod, setOneMethod] = useState(false)

  const [t, i18n] = useTranslation()

  const navigate = useNavigate()

  const {
    getPaymentInfo,
    getPaymentMethods,
    putPaymentInfo,
    loading,
    error,
    clearError,
    setLoading
  } = usePaymentService()

  const sendPaymentData = (id, payment, fields) => {
    putPaymentInfo(id, payment, fields).then(res => {
      setPayData(res)
      setOpen(true)
    })
  }

  const fieldsValidation = (field, value) => {
    setValidation(toRegExp(field.regex).test(value))
  }

  useEffect(() => {
    const interval = setTimeout(() => clearError(), 5000)

    return () => {
      clearTimeout(interval)
    }
  }, [error])

  useEffect(() => {
    getPaymentMethods()
      .then(res => setPaymentMethods(res))
      .catch(e => e && navigate('not-found'))

    getPaymentInfo()
      .then(res => {
        setPaymentInfo(res)
        i18n.changeLanguage(res.lang)
      })
      .catch(e => e && navigate('not-found'))
  }, [])

  useEffect(() => {
    if (paymentMethods.length === 1) {
      paymentMethods.map(payment => {
        if (payment.fields.length === 0) {
          setOneMethod(true)
          sendPaymentData(payment.id, payment, payment.fields)
        }
      })
    }
  }, [paymentMethods])

  const [value, setValue] = useState(0)
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
          bgcolor: 'background.paper',
          display: 'flex',
          visibility: loading ? 'hidden' : 'visible',
          height: 450,
          justifyContent: 'center',
          p: 1
        }}
      >
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          scrollButtons={true}
          allowScrollButtonsMobile
          onChange={handleChange}
          aria-label="Payment Methods"
          sx={{ borderRight: 1, borderColor: 'divider', width: '280px', pt: 1 }}
        >
          {paymentMethods.map((payment, i) => (
            <Tab
              style={{ fontSize: '14px', minHeight: '32px', padding: 0 }}
              key={payment.id}
              label={payment.name}
              {...a11yProps(i)}
              onClick={() => clearError}
            />
          ))}
        </Tabs>
        {paymentMethods.map((payment, i) => (
          <TabPanel key={payment.id} value={value} index={i} style={{ width: '400px' }}>
            {payment.fields.map((field, i) => (
              <TextField
                key={i}
                onChange={e => {
                  fieldsValidation(field, e.target.value)
                  setFields({
                    [field.key]: e.target.value
                  })
                }}
                label={field.name}
                margin="normal"
                required
                id="filled"
                error={!validation}
                helperText={!validation ? field.message : null}
                fullWidth
                sx={{ display: 'block' }}
              />
            ))}
            {Object.keys(paymentInfo).map(
              (key, i) =>
                key !== 'lang' && (
                  <TextField
                    key={i}
                    disabled
                    id="filled"
                    label={key.charAt(0).toUpperCase() + key.slice(1)}
                    defaultValue={paymentInfo[key]}
                    margin="normal"
                    fullWidth
                    sx={{ display: 'block' }}
                  />
                )
            )}
            <LoadingButton
              sx={{
                margin: '0 auto',
                display: 'flex',
                mt: '20px'
              }}
              onChange={fieldsValidation}
              variant="outlined"
              onClick={() => {
                sendPaymentData(payment.id, payment, fields)
              }}
              disabled={payment.fields.length !== 0 ? !validation : false}
            >
              {t('buttons.deposit')}
            </LoadingButton>
            {error ? <ErrorMessage error={error} /> : null}
          </TabPanel>
        ))}
        <PaymentDialog
          open={open}
          setOpen={setOpen}
          payData={payData}
          oneMethod={oneMethod}
          setLoading={setLoading}
        />
      </Box>
      {loading && !error ? <Loading loading={loading} /> : null}
    </>
  )
}

export { Payment }
