import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material'

import { SendForm } from '../Form'

const PaymentDialog = ({ open, setOpen, payData, oneMethod, setLoading }) => {
  const [t] = useTranslation()

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div>
      <Dialog open={open} aria-labelledby="responsive-dialog-title">
        <DialogTitle sx={{ fontSize: '24px' }} id="responsive-dialog-title">
          {t('payment.confirmAsk')}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleClose}>{t('payment.disagree')}</Button>
          <SendForm
            payData={payData}
            oneMethod={oneMethod}
            setLoading={setLoading}
            setOpen={setOpen}
          />
        </DialogActions>
      </Dialog>
    </div>
  )
}

export { PaymentDialog }
