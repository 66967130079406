import axios from 'axios'
import { useState, useCallback } from 'react'

const useHttp = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const request = useCallback(async (url, method = 'GET', data = null) => {
    setLoading(true)

    try {
      let response
      await axios(url, { method, data, withCredentials: true }).then(res => {
        response = res
      })

      setLoading(false)
      return response
    } catch (e) {
      setLoading(false)
      setError(e.message)
      throw e
    }
  }, [])

  const clearError = useCallback(() => {
    setError(null)
  }, [])

  return { loading, request, error, clearError, setLoading }
}

export { useHttp }
