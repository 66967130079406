import React from 'react'
import { Stack, Alert } from '@mui/material'

const ErrorMessage = ({ error }) => {
  return (
    <Stack sx={{ width: '100%', marginTop: '50px' }} spacing={2}>
      <Alert severity="error">{error.message}</Alert>
    </Stack>
  )
}

export { ErrorMessage }
