import React from 'react'
import { Stack, CircularProgress, Backdrop } from '@mui/material'

const Loading = ({ loading }) => {
  return (
    <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
      <Stack sx={{ color: 'grey.500' }} spacing={2} direction="row" style={{ display: 'block' }}>
        <CircularProgress sx={{ display: 'flex', color: '#38c172', margin: '0 auto' }} />
      </Stack>
    </Backdrop>
  )
}

export { Loading }
