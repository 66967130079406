import { useNavigate } from 'react-router-dom'
import { useHttp } from '../hooks/http.hook'

const _apiBaseDev = '' // only for development, insert url here
const setHash = window.location.hash.slice(1)

const usePaymentService = () => {
  const { clearError, loading, error, request, setLoading } = useHttp()
  const navigate = useNavigate()

  const getPaymentMethods = async () => {
    let res = await request(`${_apiBaseDev}/back/paymentmethods/${setHash}`)

    return res.data.data
  }
  const getPaymentInfo = async () => {
    let res = await request(`${_apiBaseDev}/back/invoices/${setHash}`)

    return res.data.data
  }
  const getWithdrawalInfo = async () => {
    let res = await request(`${_apiBaseDev}/back/withdrawals/${setHash}`)

    return res.data.data
  }
  const getWithdrawalMethods = async () => {
    let res = await request(`${_apiBaseDev}/back/withdrawalmethods/${setHash}`).catch(() =>
      navigate('/not-found')
    )

    return res.data.data
  }

  const putPaymentInfo = async (id, payment, fields) => {
    let data = {
      _method: 'PUT',
      payment_method_id: id
    }

    payment.fields.length > 0 ? (data['fields'] = fields) : null

    let res = await request(`${_apiBaseDev}/back/invoices/${setHash}`, 'PUT', data)

    return res.data.data
  }

  const putWithdrawalSelectedId = async () => {
    let data = {
      _method: 'PUT',
      withdrawal_method_id: 1
    }
    let res = await request(`${_apiBaseDev}/back/withdrawals/${setHash}`, 'PUT', data)

    return res.data.data
  }

  const putWithdrawalInfo = async cardNumber => {
    let data = {
      _method: 'PUT',
      card_number: cardNumber
    }

    await request(`${_apiBaseDev}/back/withdrawals/save/${setHash}`, 'POST', data)
      .then(res => {
        console.log('%c Success', 'color: #bada55; font-size: 24px')
        window.location.replace(res.data.data.url)
      })
      .catch(e => {
        navigate('/not-found')
        console.log(e)
      })
  }

  return {
    getPaymentInfo,
    getPaymentMethods,
    getWithdrawalInfo,
    getWithdrawalMethods,
    putPaymentInfo,
    putWithdrawalInfo,
    putWithdrawalSelectedId,
    clearError,
    setLoading,
    loading,
    error
  }
}

export { usePaymentService, setHash, _apiBaseDev }
