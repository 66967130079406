import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { resources } from './translations'

i18n.use(initReactI18next).init({
  fallbackLng: 'en',
  debug: true,
  resources
})

export default i18n
