import React, { useEffect, useState } from 'react'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box, Tab } from '@mui/material'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import CreditCardIcon from '@mui/icons-material/CreditCard'

import { usePaymentService } from '../../../services/paymentService'

import { Loading } from '../../Loading/Loading'
import { CreditCardForm } from '../../CreditCardForm'

import './style.scss'

const Withdrawals = () => {
  const [withdrawalMethods, setWithdrawalMethods] = useState([])
  const [value, setValue] = useState('Cards')

  const { getWithdrawalInfo, getWithdrawalMethods, loading } = usePaymentService()

  const getIcon = name => {
    switch (name) {
      case 'Cards':
        return <CreditCardIcon />
      case 'Banks':
        return <AccountBalanceIcon />
    }
  }
  useEffect(() => {
    getWithdrawalMethods().then(setWithdrawalMethods)
    getWithdrawalInfo()
  }, [])

  return (
    <Box style={{ margin: 'auto', width: '100%' }}>
      {!loading ? (
        <Box className="card__element" sx={{ maxWidth: 1200, margin: 'auto' }}>
          <TabContext value={value}>
            <TabList
              style={{ width: '100%', height: '80px', backgroundColor: '#6200ea' }}
              onChange={(e, newValue) => setValue(newValue)}
              aria-label="basic tabs example"
            >
              {withdrawalMethods.map((item, index) => {
                return (
                  <Tab
                    label={`${item.name}`}
                    icon={getIcon(item.name)}
                    key={index}
                    value={item.name.toString()}
                    style={{
                      width: `${100 / withdrawalMethods.length}%`,
                      height: '80px',
                      color: '#fff',
                      maxWidth: '100%'
                    }}
                  />
                )
              })}
            </TabList>
            <TabPanel value="Cards" style={{ border: '2px solid #6200ea' }}>
              <CreditCardForm />
            </TabPanel>
          </TabContext>
        </Box>
      ) : (
        <Loading loading={loading} />
      )}
    </Box>
  )
}

export { Withdrawals }
