import React from 'react'
import { useTranslation } from 'react-i18next'

import './style.scss'

const NotFound = () => {
  const [t] = useTranslation()

  return (
    <div className="container">
      <div className="error-page">
        <p className="error-text">ERROR</p>
        <div className="message">{t('error')}</div>
      </div>
    </div>
  )
}

export { NotFound }
