import React, { useEffect, useState, useRef } from 'react'
import { Button } from '@mui/material'
import { useTranslation } from 'react-i18next'

const SendForm = ({ payData, oneMethod, setLoading, setOpen }) => {
  const [utmTags, setUtmTags] = useState(null)
  const paymentDataForm = useRef(null)
  const [t] = useTranslation()

  let clone = Object.assign({}, payData)
  delete clone['url']
  delete clone['method']

  useEffect(() => {
    console.log(payData)
    searchUtmTags()
    
    // if (payData && oneMethod) {
        //paymentDataForm.current.submit()
        
      // setOpen(false)
      // setLoading(true)
    // }
  }, [payData.url])

  useEffect(() => {
    if(utmTags)
      if (payData && oneMethod) {
        paymentDataForm.current.submit()
        setOpen(false)
        setLoading(true)
    }
  }, [utmTags])

  const searchUtmTags = () => {
    if (payData.url) {
      const urlSearch = new URL(payData.url)
      setUtmTags(
        Object.fromEntries(
          urlSearch.search
            .slice(1)
            .split('&')
            .filter(item => item.indexOf(' '))
            .map(item => item.split('='))
        )
      )
    }
  }

  function handleSubmit(e) {
    setOpen(false)
    setLoading(true)
    e.target.submit()
  }

  return (
    <form
      method={payData?.method === 'GET' ? 'GET' : 'POST'}
      action={payData.url}
      onSubmit={e => handleSubmit(e)}
      ref={paymentDataForm}
    >
      {utmTags && <>
        {Object.keys(clone).map((name, value) => (
          <input type="hidden" name={name} key={value} value={clone[name]} />
        ))}
        {Object.keys(utmTags).map((name, value) => (
          <input type="hidden" name={name} key={value} value={utmTags[name]} />
        ))}
      </>}
      <Button type="submit">{t('payment.agree')}</Button>
    </form>
  )
}

export { SendForm }
