export const resources = {
  en: {
    translation: {
      buttons: {
        submit: 'Submit',
        cancel: 'Cancel',
        deposit: 'Deposit'
      },
      payment: {
        confirmAsk: 'Confirm Payment?',
        disagree: 'Disagree',
        agree: 'Agree'
      },
      loading: 'Loading, please wait...',
      error: 'Ooops, something went wrong...'
    }
  },
  ru: {
    translation: {
      buttons: {
        submit: 'Продолжить',
        cancel: 'Отмена',
        deposit: 'Оплатить'
      },
      payment: {
        confirmAsk: 'Потдвердить платеж?',
        disagree: 'Отменить',
        agree: 'Потдвердить'
      },
      loading: 'Загрузка, пожалуйста подождите...',
      error: 'Упс, что-то пошло не так...'
    }
  }
}
